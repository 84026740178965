import API from "../../api/index";

export default {
  getAccountInfo: params => {
    return API.requestGet({
      params,
      url: "user/account"
    });
  },
  // 添加子账号
  saveAccountInfo: params => {
    return API.requestPost({
      data: params,
      url: "user/account"
    });
  },
  // 发送验证码
  getCode: params => {
    return API.requestPost(
      {
        url: "/api/sms/sendSms",
        data: params
      },
      false
    );
  },
};
